/*!
 * Name: Atlas
 * Author: Zengenti Ltd
 * Author URI: http://zengenti.com
 * Description: A modern front-end framework
 * Version: 0.0.1
 */

var app = (function ($) {
    'use strict';

    // private alias to settings
    var s;

    return {
        settings: function() {
            this.$window            = $(window);
            this.$body              = $('body');
            this.$header            = $('.header');
            this.$headerSearch      = $('.header__triangle, .header__search');
            this.$headerMobileNav   = $('.header__nav');
            this.$headerClose       = $('.header__close');

            this.$overlay           = $('.overlay');
            this.$navDesktop        = $('.header__nav-desktop');
            this.$navMobileOverlay  = $('.header__nav-mobile');
            this.$navDesktop        = $('.header__nav-desktop .nav__item');
            this.$navSub            = $('.header__nav-desktop .nav__sub');

            this.$megamenu           = $('.megamenu');

            this.$hasChildren       = $('.has-children');
            this.$jsBreakpoint      = '';
        },

        init: function() {
            s = new this.settings();

            this.jsBreakpoints();
            this.header();
            this.inPageNav();
            this.sectionMenu();
            this.filterToggle();
            this.allCarousels();
            this.extraWidthStoriesCarousels();
            this.extraWidthEventsCarousels();
            this.extraWidthExpertsCarousels();
            this.extraWidthPortalEventsCarousels();
            this.scrollToTop();
            this.showcase();
            this.accordian();
            this.timeline();
            this.filterList();
            //this.checkboxes();
            this.portal();
            this.portalMenu();
            this.clipPathSupport();
            //this.portalMenu();
            this.dataTable();
            this.heightMatch();

            this.bindUIActions();       // UI Actions (click, focus etc)
            this.bindWindowActions();   // Window actions (scrolling, resize etc)
        },

        jsBreakpoints: function() { 
            $(window).on('resize load', function() {
                var windowWidth = $( window ).width();

                if(windowWidth <= 640){
                    s.$jsBreakpoint = 'small';
                } else if(windowWidth >= 641 && windowWidth <= 1191){
                    s.$jsBreakpoint = 'medium';
                } else if(windowWidth >= 1192 && windowWidth <= 1440){
                    s.$jsBreakpoint = 'large';
                } else if(windowWidth >= 1441 && windowWidth <= 1920){
                    s.$jsBreakpoint = 'xlarge';
                } else if(windowWidth >= 1921){
                    s.$jsBreakpoint = 'xxlarge';
                }
                
            }).trigger('resize');
        },

        header: function() { 
            // Header icons 
            s.$headerSearch.click(function() {
                s.$body.removeClass('noscroll');
                s.$header.removeClass('nav--active');
                s.$overlay.removeClass('search--active');
                s.$overlay.removeClass('nav--active');
                s.$navMobileOverlay.removeClass('nav--active');
                s.$navDesktop.removeClass('active');
                s.$navSub.removeClass('nav__sub--active');
                s.$megamenu.removeClass('active');
                $('.quick-links__dropdown').css('display', 'none');
                s.$body.addClass('noscroll');
                s.$overlay.removeClass('menu--active');
                s.$overlay.addClass('search--active');
                $('.quick-links__dropdown').css('display', 'none');
            });

            s.$headerMobileNav.click(function() {
                s.$body.removeClass('noscroll');
                s.$header.removeClass('nav--active');
                s.$overlay.removeClass('search--active');
                s.$overlay.removeClass('nav--active');
                s.$navMobileOverlay.removeClass('nav--active');
                s.$navDesktop.removeClass('active');
                s.$navSub.removeClass('nav__sub--active');
                s.$megamenu.removeClass('active');
                $('.quick-links__dropdown').css('display', 'none');
                s.$body.addClass('noscroll');
                s.$overlay.removeClass('search--active');
                s.$overlay.removeClass('nav--active');                
                s.$navMobileOverlay.addClass('nav--active');


            });

            //var menuTimer;

            s.$headerClose.click(function() {
                s.$body.removeClass('noscroll');
                s.$header.removeClass('nav--active');
                s.$overlay.removeClass('search--active');
                s.$overlay.removeClass('nav--active');
                s.$navMobileOverlay.removeClass('nav--active');
                s.$navDesktop.removeClass('active');
                s.$navSub.removeClass('nav__sub--active');
                s.$megamenu.removeClass('active');
                $('.quick-links__dropdown').css('display', 'none');
                //clearTimeout(menuTimer);
            });

            // Mobile navigation
            $('.has-children').click(function () {
                // Gets the 'name' of the element selected
                var target = $('#' + $(this).data('target'));
                // Updates the data of the back button to the previous menu
                $('#nav__back').attr('data-target', $('.menu.active').attr('id'));
                // Removes the active class of the current active menu and applies it to the new menu
                $('.menu.active').removeClass('active');
                $(target).addClass('active');
                // If the root menu has the active of active, removes the active state of the back button
                if (!$('.root-menu').hasClass('active')) {
                    $('#nav__back').addClass('active');
                } else {
                    $('#nav__back').removeClass('active');
                }
                // Updates the breadcrumb of the name selected
                //$('#nav__breadcrumb').text(target.data('name'));
                $('.breadcrumb__item').removeClass('breadcrumb__active');
                $('#nav__breadcrumb').append('<a href="#" class="breadcrumb__item breadcrumb__active" data-target="' + $('.menu.active').attr('id') + '">' + target.data('name') + '</a>');
            });

            $('.has-children a.nav__link').click(function (e) {
                e.preventDefault();
            });

            $('#nav__back').click(function () {
                var target = $('#' + $('.menu.active').data('parent'));
                $('#nav__breadcrumb .breadcrumb__item').last().remove();
                $('#nav__breadcrumb .breadcrumb__item').last().addClass('breadcrumb__active');

                $('#nav__back').attr('data-target', $(target).data('id'));
                $('.menu.active').removeClass('active');
                $(target).addClass('active');
                if (!$('.root-menu').hasClass('active')) {
                    $('#nav__back').addClass('active');
                } else {
                    $('#nav__back').removeClass('active');
                }
            });

            $('body').on('click', 'a.breadcrumb__item', function() {
                var target = $('#' + $(this).data('target'));
                var targetId = $(this).data('target');
                var lastBreadCrumb = $('#nav__breadcrumb .breadcrumb__item').last().data('target');

                while(targetId != lastBreadCrumb) {
                    $('#nav__breadcrumb .breadcrumb__item').last().remove();
                    lastBreadCrumb = $('#nav__breadcrumb .breadcrumb__item').last().data('target');
                }
                $('#nav__breadcrumb .breadcrumb__item').last().addClass('breadcrumb__active');
                $('#nav__back').attr('data-target', $(target).data('id'));
                $('.menu.active').removeClass('active');
                $(target).addClass('active');
                if (!$('.root-menu').hasClass('active')) {
                    $('#nav__back').addClass('active');
                } else {
                    $('#nav__back').removeClass('active');
                }
            });

            

            function navigationHeight() {
                var bodyHeight = s.$body.height();
                var headerHeight = s.$header.height();

                var menuHeight = bodyHeight - headerHeight;
                menuHeight = menuHeight / 100 * 90;
                $('.megamenu').height(menuHeight + 'px');
                $('.megamenu .megamenu__menu').height(menuHeight - 0 + 'px');
                $('.megamenu .megamenu__menu .nav__list').height(menuHeight - 150 + 'px');
            }
            
            $(document).on('mouseover', '.header__nav-desktop ul > li.level1', function() {
                $('.header__nav-desktop ul > li.level1').removeClass('active');
            });

            var activeNavItem;


            $('.header__nav-desktop ul > li.level1').hoverIntent(

                function() { 
                    $('.megamenu .megamenu__menu').children('ul').appendTo(activeNavItem);
                    $('.header__nav-desktop ul > li.level1').removeClass('active');
                    var that = this;
                    if ($(this).hasClass('haschildren')) {
                        $('.megamenu__menu, .megamenu__desc').show();
                        $('.megamenu__menu, .megamenu__desc').empty(); 

                        // if (menuTimer != null) {
                        //     clearTimeout(menuTimer);
                        // }
                        s.$body.scrollTop(0);
                        s.$body.addClass('noscroll');
                        s.$header.addClass('nav--active');
                        s.$overlay.addClass('nav--active');
                        s.$megamenu.addClass('active');
                        //$(this).find('.nav__title').addClass('active');
                        $('.header__triangle').addClass('triangle-blue');
                        $('.quick-links__dropdown').css('display', 'none');

                        // menuTimer = setTimeout(function() {

                        // }, 300);

                    
                    


                        // var delay=1000, setTimeoutConst;
                        // $('.img').on('hover', function() {
                        // setTimeoutConst = setTimeout(function() {
                        //     // do something
                        // }, delay);
                        // }, function() {
                        // clearTimeout(setTimeoutConst);
                        // });

                        var menuTitle = $(that).find('.nav__title').text();
                        /*if (menuTitle == 'Contact us') {
                            $('.megamenu .megamenu__menu').addClass('megamenu__menu--full');
                        } else {
                            $('.megamenu .megamenu__menu').removeClass('megamenu__menu--full');
                        }*/
                        $('.megamenu .megamenu__menu').append('<p class="megamenu__title">' + menuTitle + '</p>');

                        activeNavItem = $(that);

                        $(that).children('ul').appendTo('.megamenu .megamenu__menu');

                        navigationHeight();

                        $(window).onresize = function() {
                            navigationHeight();
                        };
                    }
                    else {
                        s.$body.removeClass('noscroll');
                        s.$header.removeClass('nav--active');
                        s.$overlay.removeClass('search--active');
                        s.$overlay.removeClass('nav--active');
                        s.$navMobileOverlay.removeClass('nav--active');
                        s.$navDesktop.removeClass('active');
                        s.$navSub.removeClass('nav__sub--active');
                        s.$megamenu.removeClass('active');
                        $('.quick-links__dropdown').css('display', 'none');

                    }
                });


            // $('.header__nav-desktop ul > li.level1').mouseleave(function() 
            // {
            //     if (menuTimer != null)
            //     {
            //         clearTimeout(menuTimer);

            //         menuTimer = null;
            //     }
            // });

            // $(window).on('resize load', function() {
            //     s.$body.removeClass('noscroll');
            //     s.$header.removeClass('nav--active');
            //     s.$overlay.removeClass('search--active');
            //     s.$overlay.removeClass('nav--active');
            //     s.$navMobileOverlay.removeClass('nav--active');
            //     s.$navDesktop.removeClass('active');
            //     s.$navSub.removeClass('nav__sub--active');
            //     s.$megamenu.removeClass('active');
            //     $('.quick-links__dropdown').css('display', 'none');
            //     $('.megamenu .megamenu__menu').children('ul').appendTo(activeNavItem);
            // }).trigger('resize');
            

            $('.quick-links__item').on('click', function() {
                if($(this).find('.quick-links__dropdown').is(':visible')) {
                    $(this).find('.quick-links__dropdown').css('display', 'none');
                } else {
                    $('.quick-links__dropdown').css('display', 'none');
                    $(this).find('.quick-links__dropdown').css('display', 'block');
                }
            }).on('click', '.quick-links__dropdown', function(e) {
                e.stopPropagation();
            });
        },

        inPageNav: function() {
            if( $('.inpage-nav').length ) 
            {
                //Sticky nav
                var inPageNavPos;
                $(window).on('resize load', function() {
                    inPageNavPos = $( '.inpage-nav' ).offset().top;
                }).trigger('resize');


                $(window).scroll (function () {
                    var scroll = $(window).scrollTop();

                    if (scroll >= inPageNavPos) {
                        $('.inpage-nav').addClass('sticky-page-nav');
                        $('#overview').addClass('sticky-page-active');
                        $(window).scrollTop() + 1000;
                    } else if (scroll < inPageNavPos) {
                        $('.inpage-nav').removeClass('sticky-page-nav');
                        $('#overview').removeClass('sticky-page-active');
                    }
                });
            }
            

            // Active menu option

            $('.inpage-nav__list .inpage-nav__item a').on('click', function(e) {
                e.preventDefault();
                var data_id = $(this).attr('data-id');


                $('html, body').animate({
                    scrollTop: $('#'+data_id).offset().top
                }, '100');
            });

            $(window).scroll (function () {
                var scrollPosition = $(window).scrollTop();
                if ($('.inpage-nav__list .inpage-nav__item a').length) {
                    $('.inpage-nav__list .inpage-nav__item a').each(function () {
                        var currentLink = $(this);
                        var refElement = $(currentLink.attr('href'));
                        if (refElement.position().top <= scrollPosition + 100 && refElement.position().top + refElement.height() > scrollPosition) {
                            $('.inpage-nav__list .inpage-nav__item a').removeAttr( 'aria-selected' );
                            currentLink.attr('aria-selected','true');
                        }
                        else{
                            currentLink.removeAttr( 'aria-selected' );
                        }
                    });
                }
            });

        },

        sectionMenu: function() {
            $('.section--menu_toggle').click(function() {
                $(this).parent( '.section--menu' ).toggleClass( 'show' );
            });
        },

        filterToggle: function() {
            $('.filter-results').click(function() {
                $('.filter--overlay').toggleClass( 'show' );
            });
            $('.close--filter').click(function() {
                $('.filter--overlay').removeClass( 'show' );
            });
        },

        allCarousels: function() {


            $('.hero-slider').cycle({
                fx: 'scrollHorz',
                slides: '.hero-slide',
                speed: 600,
                next: '.hero-next',
                prev: '.hero-prev',
                paused: false
            });

            $( '.hero-slider' ).on( 'cycle-after', function(  ) {
                $( '.overlay-1' ).animate({
                    opacity: 0.5,
                    top: '0'
                }, 400, function() {
                    // Animation complete.
                });
                if(s.$jsBreakpoint == 'small') {
                    $( '.overlay-1' ).animate({
                        opacity: 0.5,
                        top: '40%'
                    }, 400, function() {
                        // Animation complete.
                    });
                } else {
                    $( '.overlay-1' ).animate({
                        opacity: 0.5,
                        top: '0'
                    }, 400, function() {
                        // Animation complete.
                    });
                }

                $( '.overlay-2' ).animate({
                    opacity: 0.5,
                    bottom: '0'
                }, 400, function() {
                    // Animation complete.
                });

            });
            $( '.hero-slider' ).on( 'cycle-before', function(  ) {
                $( '.overlay-1' ).animate({
                    opacity: 0,
                    top: '-=1000'
                }, 400, function() {
                    // Animation complete.
                });

                $( '.overlay-2' ).animate({
                    opacity: 0,
                    bottom: '-=1000'
                }, 400, function() {
                    // Animation complete.
                });
            });

            $('.events-slider').cycle({
                fx: 'carousel',
                slides: '.card',
                speed: 600,
                next: '.event-next',
                prev: '.event-prev',
                paused: true
            });

            $('.full-width-slider').cycle({
                fx: 'carousel',
                slides: '.full-card',
                speed: 600,
                next: '.full-next',
                prev: '.full-prev',
                paused: true,
                allowWrap: false
            });

            $('.portal-slider').cycle({
                fx: 'carousel',
                slides: '.full-card',
                speed: 600,
                next: '.full-next',
                prev: '.full-prev',
                paused: true,
                allowWrap: false
            });

            
            var currentSlide = '';
            var totalSlide = '';
            $( '.stories-slider' ).on( 'cycle-update-view', function(e, optionHash) {
                currentSlide = optionHash.currSlide + 1;
                totalSlide = optionHash.slideCount;
                if(currentSlide <= 9) {
                    currentSlide = '0' + currentSlide;
                }
                if(totalSlide <= 9) {
                    totalSlide = '0' + totalSlide;
                }
                var caption = currentSlide + '</br>/' + totalSlide;
                $('.slide-number').html(caption);
            });
            $('.stories-slider').cycle({
                fx: 'carousel',
                slides: '.story-slide',
                speed: 600,
                next: '.story-next',
                prev: '.story-prev',
                paused: true,
                allowWrap: false
            });

            $('.story-slide--information').cycle({
                fx: 'fade',
                slides: '.story-slide-item',
                speed: 600,
                next: '.story-next',
                prev: '.story-prev',
                paused: true
            });

            $('.experts-slider').cycle({
                fx: 'carousel',
                slides: '.experts-slide',
                speed: 600,
                next: '.experts-next',
                prev: '.experts-prev',
                paused: true
            });

            $('.filter--overlay_slider').cycle({
                fx: 'scrollHorz',
                timeout: 0,
                slides: '.filter--overlay_slide',
                speed: 300,
                next: '.next--filter',
                prev: '.prev--filter',
                paused: true,
                allowWrap: false
            });

            $('.latest-media-slider').cycle({
                fx: 'carousel',
                slides: '.ui-card',
                speed: 600,
                next: '.latest-media-next',
                prev: '.latest-media-prev',
                paused: true,
                allowWrap: false
            });

            $('.testimonials-slider').cycle({
                fx: 'scrollHorz',
                slides: '.testimonials-slider_slide',
                speed: 600,
                next: '.testimonials-next',
                prev: '.testimonials-prev',
                paused: true
            });

            

            $( '.testimonials-slider' ).on( 'cycle-update-view', function(e, optionHash) {
                currentSlide = optionHash.currSlide + 1;
                totalSlide = optionHash.slideCount;
                var caption = currentSlide + '/' + totalSlide;
                $('.testimonials-slide-number').html(caption);
            });

            $('.content--slider').each(function() {
                $(this).cycle({
                    fx: 'scrollHorz',
                    slides: '.content--slider_slide',  // if not using just images as the children of .galleryContainer.
                    speed: 600,
                    next: $(this).find('.content-next'), // finding this slideshow's controls box using $this
                    prev: $(this).find('.content-prev'),
                    paused: true
                });
            });

            $('.bg_image--slider').each(function() {
                $(this).cycle({
                    fx: 'scrollHorz',
                    slides: '.bg_image-slider_slide',  // if not using just images as the children of .galleryContainer.
                    speed: 600,
                    next: $(this).siblings('.row').find('.content-next'), // finding this slideshow's controls box using $this
                    prev: $(this).siblings('.row').find('.content-prev'),
                    paused: true
                });
            });

            $('.cycle-play').click(function() {
                $('.hero-slider').cycle('resume');
                $(this).addClass('control-hide');
                $('.cycle-pause').removeClass('control-hide');
            });
            
            $('.cycle-pause').click(function() {
                $('.hero-slider').cycle('pause');
                $(this).addClass('control-hide');
                $('.cycle-play').removeClass('control-hide');
            });

            s.$window.resize(function() {
                if (s.$window.width() > 1192) {
                    $('.latest-media-slider').cycle('goto', 0);
                }
            });
        },

        extraWidthStoriesCarousels: function() {
            var storiesRowWidth = $( '.stories-row' ).width();

            $(window).on('resize load', function() {
                var windowWidth = $( window ).width();

                // Work out margins
                var remainingMargin = windowWidth - storiesRowWidth;
                var leftPos = remainingMargin / 2;

                // Work out remaining browser width
                var storiesTitleWidth = $( '.stories-title' ).outerWidth();
                var storiesSliderWidth = windowWidth - leftPos - storiesTitleWidth;

                /// Set CSS
                if(s.$jsBreakpoint == 'small' || s.$jsBreakpoint == 'medium') {
                    $('.stories-row').css({'max-width': '100%'});
                    $('.stories-title').css({'margin-left': '0px'});
                    $('.slide-number').css({'margin-left': '0px'});
                    $('.stories-slider').css({'width': '100%'});
                } else {
                    $('.stories-row').css({'max-width': '100%'});
                    $('.stories-title').css({'margin-left': leftPos});
                    $('.slide-number').css({'margin-left': leftPos});
                    $('.stories-slider').css({'width': storiesSliderWidth});
                }
            }).trigger('resize');
        },

        extraWidthEventsCarousels: function() {
            var eventsRowWidth = $( '.upcoming-events-row' ).width();

            $(window).on('resize load', function() {
                var windowWidth = $( window ).width();

                // Work out margins
                var remainingMargin = windowWidth - eventsRowWidth;
                var leftPos = remainingMargin / 2;

                // Work out remaining browser width
                var eventsTitleWidth = $( '.upcoming-events-title' ).outerWidth();
                var eventsSliderWidth = windowWidth - leftPos - eventsTitleWidth;


                /// Set CSS
                if(s.$jsBreakpoint == 'small' || s.$jsBreakpoint == 'medium') {
                    $('.upcoming-events-row').css({'max-width': '100%'});
                    $('.upcoming-events-title').css({'margin-left': '0px'});
                    $('.events-slider').css({'width': '100%'});
                } else {
                    $('.upcoming-events-row').css({'max-width': '100%'});
                    $('.upcoming-events-title').css({'margin-left': leftPos});
                    $('.events-slider').css({'width': eventsSliderWidth});
                }
            }).trigger('resize');
        },

        extraWidthPortalEventsCarousels: function() {
            //var eventsRowWidth = $( '.upcoming-portal-events-row' ).width();

            $(window).on('resize load', function() {
                var windowWidth = $( window ).width() - 100;

                // Work out margins
               //var remainingMargin = windowWidth - eventsRowWidth;
                var leftPos = 0;

                // Work out remaining browser width
                var eventsTitleWidth = $( '.upcoming-events-title' ).outerWidth();
                var eventsSliderWidth = windowWidth - leftPos - eventsTitleWidth;


                /// Set CSS
                if(s.$jsBreakpoint == 'small' || s.$jsBreakpoint == 'medium') {
                    $('.upcoming-portal-events-row').css({'max-width': '100%'});
                    $('.upcoming-portal-events-row .upcoming-events-title').css({'margin-left': '0px'});
                    $('.upcoming-portal-events-row .events-slider').css({'width': '100%'});
                } else {
                    $('.upcoming-portal-events-row').css({'max-width': '100%'});
                    $('.upcoming-portal-events-row .upcoming-events-title').css({'margin-left': leftPos});
                    $('.upcoming-portal-events-row .events-slider').css({'width': eventsSliderWidth});
                }
            }).trigger('resize');
        },

        extraWidthExpertsCarousels: function() {
            var eventsRowWidth = $( '.experts-row' ).width();
            

            $(window).on('resize load', function() {
                var windowWidth = $( window ).width();

                // Work out margins
                var remainingMargin = windowWidth - eventsRowWidth;
                var leftPos = remainingMargin / 2;

                // Work out remaining browser width
                var eventsTitleWidth = $( '.experts-title' ).outerWidth();
                var eventsSliderWidth = windowWidth - leftPos - eventsTitleWidth;


                /// Set CSS
                if(s.$jsBreakpoint == 'small' || s.$jsBreakpoint == 'medium') {
                    $('.experts-row').css({'max-width': '100%'});
                    $('.experts-title').css({'margin-left': '0px'});
                    $('.experts-slider').css({'width': '100%'});
                } else {
                    $('.experts-row').css({'max-width': '100%'});
                    $('.experts-title').css({'margin-left': leftPos});
                    $('.experts-slider').css({'width': eventsSliderWidth});
                }
                
            }).trigger('resize');
        },

        scrollToTop: function() {
            if ($('#back-to-top').length) {
                var scrollTrigger = 400, // px
                backToTop = function () {
                    var scrollTop = $(window).scrollTop();
                    if (scrollTop > scrollTrigger) {
                        $('#back-to-top').addClass('show');
                    } else {
                        $('#back-to-top').removeClass('show');
                    }
                };
                backToTop();
                $(window).on('scroll', function () {
                    backToTop();
                });
                $('#back-to-top').on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                        scrollTop: 0
                    }, 700);
                });
            }
        },

        showcase: function() {
            $('.showcase').each(function() {
                $('.showcase--panel').hover(function() {
                    $(this).parent('.showcase').children('.showcase--panel').removeClass('active');
                    $(this).addClass('active');
                });
            });
        },

        accordian: function() {
            $('.accordian-section__title').on('click', function(e) {
              e.preventDefault();


              if ( $(this).closest( '.accordian-section' ).hasClass( 'open-accordian' ) ) {
                  $( '.accordian-section' ).each(function() {
                      $( this ).removeClass( 'open-accordian' );
                      $('.accordian-section__title').attr('aria-expanded','false');
                  });
              } else {
                  $( '.accordian-section' ).each(function() {
                      $( this ).removeClass( 'open-accordian' );
                      $('.accordian-section__title').attr('aria-expanded','false');
                  });
                  $(this).closest( '.accordian-section' ).addClass( 'open-accordian' );
                  $(this).attr('aria-expanded','true');
              }
              // Fire the resize for the parallax so the text doesn't overflow
              $(window).trigger('resize.px.parallax');
            });
        },

        timeline: function() {
            var timelineHeight;
            var timelineTabsHeight;
            $(window).on('resize load', function() {
                
                timelineHeight = $( '.activePanel' ).outerHeight(true);
                timelineTabsHeight = $( '.tabs__list' ).outerHeight(true);
                $('.tab__item a').click(function() {
                    timelineHeight = $( '.activePanel' ).outerHeight(true);
                    if(s.$jsBreakpoint != 'small') {
                        if(timelineTabsHeight > timelineHeight) {
                            $('.tabs__list').css({'height': timelineTabsHeight});
                        } else {
                            $('.tabs__list').css({'height': timelineHeight});
                        }
                    }
                });
                /// Set CSS
                if(s.$jsBreakpoint != 'small') {
                    if(timelineTabsHeight > timelineHeight) {
                        $('.tabs__list').css({'height': timelineTabsHeight});
                    } else {
                        $('.tabs__list').css({'height': timelineHeight});
                    }
                }
                
            }).trigger('resize');


            var timelineMenu;
            if ($('.tabs__menu').length) {
                $(window).on('resize load', function() {
                    timelineMenu = $('.tabs__menu').offset().top;
                }).trigger('resize');


                $(window).scroll (function () {
                    var scroll = $(window).scrollTop();
                    if (scroll >= timelineMenu) {
                        $('.tabs__menu').addClass('fixed');
                    } else if (scroll < timelineMenu) {
                        $('.tabs__menu').removeClass('fixed');
                    }
                });
            }

            

            

            $('.mobile--dropdown').click(function() {
                $(this).toggleClass('active');
                $('.tabs__list').toggleClass('collapsed');
            });
            $('.tab__item a').click(function() {
                $('.mobile--dropdown').removeClass('active');
                $('.tabs__list').addClass('collapsed');
            });
        },

        filterList: function() {
            $('.filter_list--dropdown').click(function() {
                $(this).toggleClass('active');
                $('.events-listing-filter__list').toggleClass('collapsed');
            });
            $('.events-listing-filter__link').click(function() {
                $('.filter_list--dropdown').removeClass('active');
                $('.events-listing-filter__list').addClass('collapsed');
            });
        },


        checkboxes: function() {
            $('input').iCheck();
        },

        portal: function() {
            var windowHeight = $(window).height();
            $('.portal').css({'height': windowHeight});

            $(window).on('resize load', function() {
                $('.portal__dropdown').css('display', 'none');
            }).trigger('resize');

            

            $('.portal__dropdown-link').on('click', function() {
                if($('.user--menu').find('.portal__dropdown').is(':visible')) {
                    $('.user--menu').find('.portal__dropdown').css('display', 'none');
                } else {
                    $('.portal__dropdown').css('display', 'none');
                    $('.user--menu').find('.portal__dropdown').css('display', 'block');
                }
            });


        },

        portalMenu: function() {
            /*
            var screenHeight = $(window).height();
            var menuHeight = $('#portalMenu').outerHeight();
            var menuMargin = screenHeight - menuHeight;
            var menuMarginTop = menuMargin / 2;
            $('#portalMenu').css({'margin-top': menuMarginTop});
            if(s.$jsBreakpoint == 'small') {
                $('#portalMenu').css({'margin-top': '0px'});
            }
            */
            
            $('.portal-search__button').click(function() {
                $('.portal-search__input').addClass('active');
                $('.portal-search__input .portal-search').focus();
            });
        },

        dataTable: function() {
            var table = $('#portal--opportunities').DataTable( {
                'autoWidth': false,
                'columns': [
                    {"width": "10%"},
                    {"width": "8%"},
                    {"width": "auto"},
                    {"width": "8%"},
                    {"width": "auto"},
                    {"width": "auto"},
                    {"width": "auto"}
                ],
                'searching': true,
                'paging':   true,
                // 'bLengthChange': false,
                // 'pageLength': 10,
                'info': false,
                'dom': 'lrtip',
                language: {
                    paginate: {
                        next: '>',
                        previous: '<'
                    }
                },
                columnDefs: [{
                    orderable: false,
                    targets: 'no-sort'
                }],
                initComplete: function () {
                    this.api().columns([3]).every( function () {
                        var column = this;
                        var select = $('#opportunitiesSelect')
                            .on( 'change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                    
                                );
                                column.search(val).draw();
                            } );
                        column.data().unique().sort().each( function ( d ) {
                            select.append( '<option value="'+d+'">'+d+'</option>' );
                        } );
                    } );
                }
            } );

            $('#opportunitiesSearch').on( 'keyup', function () {
                //table.column(0).search( this.value ).draw();
                table.search( this.value ).draw();
            } );

            $('#table--pagination').append($('.dataTables_paginate'));
        },

        heightMatch: function() {
            $('.content-cards .ui-card').matchHeight();
            $('.cycle-carousel-wrap .ui-card').matchHeight();
            $('.events-slider .card').matchHeight();
            //$('.latest-media-slider .cycle-carousel-wrap .ui-card').matchHeight();
        },

        clipPathSupport: function() {
            var areClipPathShapesSupported = function () {

                var base = 'clipPath',
                    prefixes = [ 'webkit', 'moz', 'ms', 'o' ],
                    properties = [ base ],
                    testElement = document.createElement( 'testelement' ),
                    attribute = 'polygon(50% 0%, 0% 100%, 100% 100%)';

                // Push the prefixed properties into the array of properties.
                for ( var i = 0, l = prefixes.length; i < l; i++ ) {
                    var prefixedProperty = prefixes[i] + base.charAt( 0 ).toUpperCase() + base.slice( 1 ); // remember to capitalize!
                    properties.push( prefixedProperty );
                }

                // Interate over the properties and see if they pass two tests.
                for ( i = 0, l = properties.length; i < l; i++ ) {
                    var property = properties[i];

                    // First, they need to even support clip-path (IE <= 11 does not)...
                    if ( testElement.style[property] === '' ) {

                        // Second, we need to see what happens when we try to create a CSS shape...
                        testElement.style[property] = attribute;
                        if ( testElement.style[property] !== '' ) {
                            return true;
                        }
                    }
                }

                return false;
            };

            if ( areClipPathShapesSupported() ) {
                document.body.classList.add( 'has-clip-path-shapes' );
            } else {
                document.body.classList.add( 'no-clip-path-shapes' );
            }
        },

        bindUIActions: function() {
            function delayParallax() {
                $(window).trigger('resize').trigger('scroll');    
            }

            $(function() {
                setTimeout(delayParallax, 1);
            });
            
        },

        bindWindowActions: function() {
            
        }
    
    };
})(jQuery);

/*!
 * Name: Pattern library
 * Component: Tabs
 * Author: Zengenti Ltd
 * Contributors: Jason Alvis
 * Author URI: http://zengenti.com
 * Description: A pattern library for front-end development
 * Version: 0.0.1
 */
var Tabs = (function($) {
    'use strict';

    // private alias to settings
    var s;

    return {
        settings: function() {
            this.$tabs = $('.tabs');
        },

        init: function() {
            s = new this.settings();
            this.bindUIActions();
        },

        bindUIActions: function() {
            // do the following for each instance of tabs
            s.$tabs.each(function() {
                var $comp = $(this);
                var $tab = $comp.find('[role="tab"]');

                // handle click on tab to show + focus tabpanel
                $tab.on('click', function(e) {
                    var $this = $(this);


                    e.preventDefault();

                    // remove focusability [sic] and aria-selected
                    $tab.attr({
                        'tabindex': '-1',
                        'aria-selected': false
                    });

                    // replace above on clicked tab
                    $this.attr({
                        'aria-selected': true,
                        'tabindex': '0'
                    });

                    // hide panels
                    $comp.find('[role="tabpanel"]').attr('aria-hidden', 'true');
                    $comp.find('[role="tabpanel"]').removeClass('activePanel');

                    // show corresponding panel
                    $('#' + $this.attr('href').substring(1)).attr('aria-hidden', 'false');
                    $('#' + $this.attr('href').substring(1)).addClass('activePanel');

                    // scroll to panel
                    //$('html, body').animate({ scrollTop: $('#' + $this).offset().top }, 500);

                    //var backgroundImages = [];

                    //var section = document.getElementById('timeline-wrapper');
                    //var current = $(this).attr('data-index');
                    //section.style.backgroundImage = 'url(' + backgroundImages[current] + ')';

                    jQuery(window).trigger('resize').trigger('scroll');

                    //custom checkboxes
                    jQuery('input').iCheck();

                });

                // change focus between tabs with arrow keys
                $tab.on('keydown', function(e) {
                    // define current, previous and next (possible) tabs
                    var $current = $(this);
                    var $prev = $current.parents('li').prev().children('[role="tab"]');
                    var $next = $current.parents('li').next().children('[role="tab"]');
                    var $target;

                    // find the direction (prev or next)
                    switch (e.keyCode) {
                        case 37:
                            $target = $prev;
                            break;
                        case 39:
                            $target = $next;
                            break;
                        default:
                            $target = false;
                            break;
                    }

                    if ($target.length) {
                        $current.attr({
                            'tabindex': '-1',
                            'aria-selected': null
                        });

                        $target.attr({
                            'tabindex': '0',
                            'aria-selected': true
                        }).focus();
                    }

                    // hide panels
                    $comp.find('[role="tabpanel"]').attr('aria-hidden', 'true');
                    $comp.find('[role="tabpanel"]').removeClass('activePanel');

                    // show corresponding panel
                    $('#' + $(document.activeElement).attr('href').substring(1)).attr('aria-hidden', 'false');
                    $('#' + $(document.activeElement).attr('href').substring(1)).addClass('activePanel');



                });
            });
        }
    };
})(jQuery);

jQuery(document).ready(function() {
    app.init();
    Tabs.init();
});

/**
 * Video Controls
 */
// Grab a handle to the video


var video = document.getElementById('video');

if (video) {
    // Turn off the default controls
    video.controls = false;
}


/* exported togglePlayPause */
// function togglePlayPause() {
//     var playpause = document.getElementById('playpause');
//     if (video.paused || video.ended) {
//         playpause.title = 'pause';
//         playpause.className = 'pause';
//         video.play();
//     } else {
//         playpause.title = 'play';
//         playpause.className = 'play';
//         video.pause();
//     }
// }

$(function() {
    'use strict';
    var modalOverlay = $('#dialog-overlay'),
        closeButton = $('.btn--modal-close'),
        openButton = $('.open-modal-button');

        var currentModal = $('*[id^="inline-modal-"]'),
            youtubeWrapper = $(currentModal.find('.youtube')),
            youtubeVideoId = $(youtubeWrapper).attr('data-embed'),
            youtubeIframe = $(youtubeWrapper).find('iframe');

            youtubeIframe.attr('src', '');
            youtubeIframe.attr('src', '//www.youtube.com/embed/' + youtubeVideoId);
    el();


    function el() {
        dialog();
    }

    function dialog() {
        closeButton.click(dialogClose),
        openButton.click(dialogOpen);

        $(document).keyup(function(ev){
            if(ev.keyCode == 27)
                $('.btn--modal-close').trigger('click');
                $('.btn--modal-close-portal').trigger('click');
        });
    }

    function dialogClose() {
        $('.dialog').attr('aria-hidden', 'true');
        $('body').removeClass('has-dialog');
        $(openButton).closest().attr('href', '#');
        $(openButton).closest().focus();
        youtubeIframe.attr('src', '');
        youtubeIframe.attr('src', '//www.youtube.com/embed/' + youtubeVideoId);


        return false;
    }

    function dialogOpen(e) {
        e.preventDefault();
        var currentVideo = $(this).attr('data-modal');

         currentModal = $('*[id^="'+ currentVideo +'"]'),
            youtubeWrapper = $(currentModal.find('.youtube')),
            youtubeVideoId = $(youtubeWrapper).attr('data-embed'),
            youtubeIframe = $(youtubeWrapper).find('iframe');
            youtubeIframe.attr('src', '');
            youtubeIframe.attr('src', '//www.youtube.com/embed/' + youtubeVideoId);
            $('video').trigger('pause');

        
        modalOverlay = $('#' + currentModal[0].id);
        //$(this).removeAttr('href');
        $(modalOverlay).attr('aria-hidden', 'false');
        $('body').addClass('has-dialog');
        closeButton.focus();
        $('#' + currentVideo + ' .youtube').trigger('click');

        if (navigator.userAgent.match(/MSIE 8/) !== null) {
              // do something in IE8
            var currentModal = $(closeButton).closest('.dialog'),
                modalHeader = $(closeButton).parent('header'),
                youtubeWrapper = $(modalHeader).siblings('div').find('.youtube'),
                youtubeVideoId = $(youtubeWrapper).attr('data-embed');
                $(currentModal).attr('aria-hidden', 'true');
                $('body').removeClass('has-dialog');
            window.open('//www.youtube.com/watch?v=' + youtubeVideoId, '_blank');
        }

        if (Function('/*@cc_on return document.documentMode===10@*/')() ){
            if( (currentModal).hasClass('dialog--video') ) {
                currentModal = $(closeButton).closest('.dialog'),
                modalHeader = $(closeButton).parent('header'),
                youtubeWrapper = $(modalHeader).siblings('div').find('.youtube'),
                youtubeVideoId = $(youtubeWrapper).attr('data-embed');
                $(currentModal).attr('aria-hidden', 'true');
                $('body').removeClass('has-dialog');
                //console.log(youtubeWrapper);
                //console.log('Video ID: ' + youtubeVideoId);
                window.open('//www.youtube.com/watch?v=' + youtubeVideoId, '_blank');
            }

        }

        var contentOverlay = $(modalOverlay).attr('id');
        var contentOverlayHeight = $('#' + contentOverlay + ' .portal-modal_content').height();
        $('#' + contentOverlay + ' .scroll-section').css({'height': contentOverlayHeight});

    }


    
(function($j) {

    var addData = function(array, name, value) {
        if (value)
        {
            array[array.length] = [name, value].join('=');
        }
    };
    var getOptions = function(options) {
        var result = $j.extend({
            expires: 365, // default 1 year
            path: '/',
            domain: '',
            secure: ''
        }, options);
        return result;
    };
    var addValue = function(array, name, value) {
        array[array.length] = name + '=' + escape(encodeURIComponent(value));
    };
    var addExpires = function(array, expires) {
        if (!expires) { return; }
        if (expires.toUTCString)
        {
            addData(array, 'expires', expires.toUTCString());
            return;
        }
        if (typeof (expires) == 'number')
        {
            var date = new Date();
            date.setTime(date.getTime() + (expires * 24 * 60 * 60 * 1000));
            addData(array, 'expires', date.toUTCString());
            return;
        }
    };
    var addSecure = function(array, secure) {
        if (secure)
        {
            array[array.length] = 'secure';
        }
    };
    var setDocumentCookie = function(array) {
        document.cookie = array.join(';');
    };
    var getCookies = function() {
        var result = {};
        if (document.cookie && document.cookie != '')
        {
            var cookies = document.cookie.split(';');
            for (var i = 0, ilen = cookies.length; i < ilen; i++)
            {
                var cookie = jQuery.trim(cookies[i]);
                var cookieInfo = cookie.split('=');
                if (cookieInfo.length > 1)
                {
                    result[cookieInfo[0].toLowerCase()] = unescape(decodeURIComponent(cookieInfo[1]));
                }
            }
        }
        return result;
    };
    var cookieHelper = {};
    var cookieHelperArray = $j(cookieHelper);
    cookieHelper = $j.extend({
        Track: function(cookieName, cookieValue, f) {
            var trackValue = cookieValue;
            var trackFunc = f;
            if (typeof (cookieValue) == 'function')
            {
                trackValue = undefined;
                trackFunc = cookieValue;
            }
            cookieHelper.bind('CookieChange', { Cookie: cookieName }, function(evt, data) {
                if (evt.data.Cookie == data.Cookie)
                {
                    if (typeof (trackValue) == 'undefined')
                    {
                        trackFunc(evt, data);
                    }
                    else
                    {
                        if (trackValue == data.NewValue)
                        {
                            trackFunc(evt, data);
                        }
                    }
                }
            });
        },
        setCookie: function(name, value, options) {
            var oldValue = cookieHelper.getCookie(name);
            options = getOptions(options);
            if (value === null)
            {
                value = '';
                options.expires = -1;
            }
            var cookieArray = [];
            addValue(cookieArray, name, value);
            addExpires(cookieArray, options.expires);
            addData(cookieArray, 'path', options.path);
            addData(cookieArray, 'domain', options.domain);
            addSecure(options.secure);
            setDocumentCookie(cookieArray);

            var data = { Cookie: name, OldValue: oldValue, NewValue: value };
            if (options.expires == -1)
            {
                data.NewValue = null;
            }
            cookieHelper.trigger('CookieChange', data);
            return this;
        },
        getCookie: function(name, defaultValue) {
            var allCookies = getCookies();
            if (typeof (allCookies[name.toLowerCase()]) != 'undefined')
            {
                return allCookies[name.toLowerCase()];
            }
            return defaultValue;
        },
        getAllCookies: function() {
            return getCookies();
        },
        removeCookie: function(name) {
            return $j.setCookie(name, null);
        }
    }, cookieHelper);
    var funcs = 'bind;trigger';
    $j.each(funcs.split(';'), function(k, f) {
        cookieHelper[f] = function() {
            $j.fn[f].apply(cookieHelperArray, arguments);
        };
    });

    $j.extend({
        Cookies: cookieHelper,
        setCookie: cookieHelper.setCookie,
        getCookie: cookieHelper.getCookie,
        getAllCookies: cookieHelper.getAllCookies,
        removeCookie: cookieHelper.removeCookie
    });
})(jQuery);

(function($j) {

    var cookieNames = {
        CMSUserName: 'ContensisCMSUserName',
        LastUserName: 'ContensisLastUserName',
        UserLanguageID: 'User_LanguageID',
        DisplayName: 'ContensisDisplayName',
        Avatar: 'ContensisAvatar'
    };
    var cmsSecurity = {
        OldUser: {},
        User: {
            CMSUserName: '',
            LastUserName: '',
            DisplayName: '',
            UserLanguageID: 0,
            Avatar: ''
        },
        IsLoggedIn: function() {
            this.Load();
            return (!!this.User.CMSUserName);
        },
        Load: function() {
            this.User.CMSUserName = $j.getCookie(cookieNames.CMSUserName);
            this.User.LastUserName = $j.getCookie(cookieNames.LastUserName);
            this.User.DisplayName = $j.getCookie(cookieNames.DisplayName);
            this.User.UserLanguageID = $j.getCookie(cookieNames.UserLanguageID);
            this.User.Avatar = $j.getCookie(cookieNames.Avatar);
        },
        LogOut: function() {
            this.OldUser = {};
            $j.extend(this.OldUser, this.User);
            $j.removeCookie(cookieNames.CMSUserName);
            this.Load();
            this.trigger('OnLogOut');
        },
        LogIn: function() {
            this.Load();
            this.trigger('OnLogIn');
        },
        RaiseLog: function() {
            if (!this.User.CMSUserName)
            {
                this.trigger('OnLogOut');
            }
            else
            {
                this.trigger('OnLogIn');
            }
        }
    };
    var funcs = 'bind;trigger';
    $j.each(funcs.split(';'), function(k, f) {
        cmsSecurity[f] = function() {
            $j.fn[f].apply($j(cmsSecurity), arguments);
        };
    });
    $j.extend({
        CMSSecurity: cmsSecurity
    });

    $j.CMSSecurity.Load();
    $j.Cookies.Track(cookieNames.CMSUserName, function() {
        $j.CMSSecurity.Load();
        $j.CMSSecurity.RaiseLog();
    });

    $j.fn.extend({
        

    });

    
    var areWeAuthenticated = function() {
        if($j.CMSSecurity.IsLoggedIn() === true) {
            var url = $(location).attr('href');
            $('.displayName').html(cmsSecurity.User.DisplayName);
            $('.loginForm').remove();
            $('.logoutLink').show();
            $('.logoutLink a').attr('href',url+'?Logout=true');
        }
    };
    areWeAuthenticated();
    
    $('a.logout').on('click', function(){
        $j.CMSSecurity.LogOut();
        $j.removeCookie('ContensisSecurity');
        $j.removeCookie(cookieNames.CMSUserName);
        $j.removeCookie(cookieNames.ContensisCMSUserName);
        $j.removeCookie(cookieNames.User_LanguageID);
        $j.removeCookie(cookieNames.ContensisDisplayName);
    });


})(jQuery);


});

/*
function loginSubmit(event, user, pass){
    //console.log ("*************** loginSubmit HIT ****************");
    event.preventDefault();
    $.ajax({
        type: 'POST',
        url: 'https://cms-twi.cloud.contensis.com/REST/Contensis/Security/Authenticate',
        // The key needs to match your method's input parameter (case-sensitive).
        data: JSON.stringify({ 'username': user.value, 'password': pass.value }),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: loginSuccess,
        failure: function(errMsg) {
            alert(errMsg);
        }
    });
}

function loginSuccess(data){
    var successObj = data;
    var loginMessage = '';
    if(successObj.LogonResult == 0 && successObj.SecurityToken != null){
        var url = window.location.pathname;
        //Set expires for 6 hours
        var date = new Date();
        date.setTime(date.getTime() + (360 * 60 * 1000));

        var location = window.location.hostname;
        var target = 'Login';
        if (location.indexOf('preview') >= 0) {
            target = 'Login.aspx';
        }

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: '/account/'+target+'?SecurityToken='+successObj.SecurityToken,
            cache: false,
            success: function(result) {
                console.log('login success');
            },
            failure: function(errMsg) {
                console.log(errMsg);
            }
        });
        window.location.replace(url);
    }
    if(successObj.LogonResult == -2) {
        loginMessage = 'The username or password entered is incorrect.';
    }
    if(successObj.LogonResult == 2 || successObj.LogonResult == -9) {
        loginMessage = 'The password for this account needs changing.';
    }
    else{
        // Do nothing
    }
    
}
*/