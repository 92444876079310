/*
 * Name: Weldasearch authentication
 */

$(document).ready(function () {


    //var missingCookies = $j.getCookie("ContensisCMSUserName") != null ? (!($j.getCookie("ContensisCMSUserName").trim())) : true;
    //var contensisUserName = encodeURIComponent($.getCookie("ContensisCMSUserName"));
    var contensisUserName = encodeURIComponent($.getCookie("ContensisLastUserName"));
    // var missingCookies = (!($j.getCookie("ContensisCMSUserName").trim()));


    $('#WeldasearchLogin a').each(function(){
        var aref = $(this);
        if (contensisUserName != "undefined") {
            var formData = new Object();
            formData.username=contensisUserName;
            $.ajax({
                type: "POST",
                url: "https://webapi.twisoftware.com/api/Weldasearch",
                headers: {"apitoken": "F6CBAADF-10FE-4351-8486-E05AD7699476"},
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                data: JSON.stringify(formData),
                success: function(result) {
                    var uname = result.username;
                    var pwd = result.password;
                    aref.attr("href", "http://weldasearch.twisoftware.com/scripts/twi/hfclient.exe?a=twi_live2&LU2&u="+uname+"&p="+pwd);
                },
                failure: function(errMsg) {
                    // eslint-disable-next-line no-console
                    console.log(errMsg);
                }
            });
        } else {
            // eslint-disable-next-line no-console
            console.log("No ContensisCMSUserName set");
        }
    });
});



