//trigger search on carriage return key press all input fields with type 'search'
//i - ignore case

var siteIsMicrosites = RegExp("/microsites/(.*)", "i");
var siteIsNSIRC = RegExp("/nsirc(.*)|NSIRCInsightsPageHTMLFile.html", "i");
var siteIsTWIIN = RegExp("/innovation-network(.*)|TWIInnovationNetworkSearchPageHTMLFile.html", "i");



var currentUrl = window.location.href;

//tests
//currentUrl = "https://preview-twi.cloud.contensis.com/microsites/isoprep/home.apsx";
//currentUrl = "https://preview-twi.cloud.contensis.com/Preview/1/microsites/isoprep/home.aspx";
//currentUrl = "https://preview-twi.cloud.contensis.com/Preview/1/microsites/isoprep/news/home.aspx";
//currentUrl = "https://www.isoprep.co.uk";
//currentUrl = "https://www.isoprep.co.uk/news";


var searchResultsPath = "/search-results.aspx";

if (siteIsNSIRC.test(currentUrl))
{
    searchResultsPath = "/nsirc/search-results.aspx";
}


if (siteIsTWIIN.test(currentUrl))
{
    searchResultsPath = "/innovation-network/search-results.aspx";
}

if (siteIsMicrosites.test(currentUrl) )
{
    currentUrl = currentUrl.replace('/Preview/1', '');
    currentUrl = currentUrl.replace('https://preview-twi.cloud.contensis.com/', '');
    var urlParts = currentUrl.split('/'); 
    searchResultsPath = '/' + urlParts[0] + '/' + urlParts[1] + '/search-results.aspx';
}


$("input[type=search]").keyup(function(e) {
       if(e.keyCode === 13){
           var elm = $(this).val();
           e.preventDefault();
            if($(this).hasClass("technical")) {
                window.location = searchResultsPath + "?term=" + elm + "&searchType=technical";
            }
            else {
                window.location = searchResultsPath + "?term=" + elm;
            } 
       }
});



$("#GenericSearch.site-search__button").click(function(e) {
    e.preventDefault();
    var elm = $("input[type=search]").val();
    window.location = searchResultsPath + "?term=" + elm;
}
);


/*
trigger search by clicking on search icon - used as fallback for react by page header search:
    /search-results.aspx
    /technical-knowledge/home.aspx 
*/
 // eslint-disable-next-line no-unused-vars
function twiSearch() {
    var elm = $("input.search-all").val();
    window.location = searchResultsPath + "?term=" + elm;
}


// trigger search by clicking on search icon - mega menu technical knowledge mobile search -- /site-elements/global/navigation-top.cshtml
// eslint-disable-next-line no-unused-vars
function twiSearchTechnical() {
    var elm = $("input.technical").val();
    window.location = searchResultsPath + "?term=" + elm + "&searchType=technical";
}

// trigger search by clicking on search icon - mega menu technical knowledge desktop search - search icon link -- /site-elements/global/navigation-top.cshtml
// eslint-disable-next-line no-unused-vars
function twiSearchTechnicalMob() {
    var elm = $("input.technicalMob").val();
    window.location = searchResultsPath + "?term=" + elm + "&searchType=technical";
}

// Trigger search on carriage return key press - careers search in page header /careers/home.aspx
var careersResultsPath = "/careers/current-vacancies.aspx";
$("input.search-careers").keyup(function(e) {
       if(e.keyCode === 13){
           var elm = $(this).val();
           e.preventDefault();
            window.location = careersResultsPath + "?term=" + elm;
       }
});

// trigger search by clicking on search icon - careers search in page header /careers/home.aspx
// eslint-disable-next-line no-unused-vars
function twiCareersSearch() {
    var elm = $("input.search-careers").val();
    window.location = careersResultsPath + "?term=" + elm;
}


//CSWIP search moved to microsites/cswip/js/cswip.js